




/**
 * The Version Control protocol
 */
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import ArchiveComponent from '@/components/shared/Archive.component.vue';
import Customer from '@/models/Customer';
import {VersionControl} from '@/misc/VersionControl';

const CustomerStore = namespace('customer');

@Component({
  components: {ArchiveComponent},
})
export default class CustomerArchiveComponent extends Vue {

  @CustomerStore.Action('loadCustomerOrigin')
  private loadCustomerOrigin!: (customerId: string) => Promise<Customer[]>;

  @Prop()
  private customer!: Customer;

  private customerVersions: Customer[] = [];
  private loaded: boolean = false;

  @Watch('customer')
  public onCustomerChange() {
    this.loadArchive();
  }

  public mounted() {
    this.loadArchive();
  }

  public async loadArchive() {
    this.loaded = false;
    this.customerVersions = await this.loadCustomerOrigin(this.customer.id!);
    this.customerVersions.sort((vc1, vc2) => VersionControl.compareLogDate(vc1, vc2));
    this.customerVersions.push(this.customer);
    this.loaded = true;
  }
}
